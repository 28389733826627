//
(function() {
  var lastTime = 0;
  var vendors = ['ms', 'moz', 'webkit', 'o'];
  for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
    window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame']
    || window[vendors[x]+'CancelRequestAnimationFrame'];
  }

  if (!window.requestAnimationFrame)
  window.requestAnimationFrame = function(callback, element) {
    var currTime = new Date().getTime();
    var timeToCall = Math.max(0, 16 - (currTime - lastTime));
    var id = window.setTimeout(function() { callback(currTime + timeToCall); },
    timeToCall);
    lastTime = currTime + timeToCall;
    return id;
  };

  if (!window.cancelAnimationFrame)
  window.cancelAnimationFrame = function(id) {
    clearTimeout(id);
  };
}());


var ENGINE = ENGINE || {};

/**
* 状態管理
*/
ENGINE.store = ENGINE.store || {};

ENGINE.store.navigationOpend     = false;
ENGINE.store.clinicOpend         = false;
ENGINE.store.navigationAnimation = false;

ENGINE.store.$body               = $('body');


/**
* ナビゲーション
*/
ENGINE.navigation = {
  $toggle   : $('.js_navigationToggle'),
  $close    : $('.js_navigationClose'),
  $target   : $('#l_navigation'),
  animation : false,

  // 初期化
  init: function() {
    this.$toggle.on('click', e => {
      this.toggle();
    });

    this.$close.on('click', e => {
      this.close();
    });
  },

  // 開閉の切り替え
  toggle: function() {
    if(ENGINE.store.navigationOpend) {
      this.close();
    } else {
      this.open();
    }
  },

  // 開く
  open: function() {

    // メニューが開いていれば終了
    if(ENGINE.store.navigationOpend) return false;

    // アニメーション中なら終了
    if(this.animation) return false;

    this.animation = true;

    this.$toggle.addClass('is_stateActive');

    ENGINE.store.$body.addClass('NAVIGATION_OPEN');

    this.$target.velocity('fadeIn', { duration: 300, easing: 'ease', complete: () => {
      ENGINE.store.navigationOpend = true;
      this.animation = false;

      // callback関数の実行 (使用箇所 : topページ)
      if (this.$toggle.data('callback-func') && (typeof(this.$toggle.data('callback-func')) == 'function')) {
        this.$toggle.data('callback-func')(true);
      }
    }});
  },

  // 閉じる
  close: function() {
    // メニューが開いていれば終了
    if(!ENGINE.store.navigationOpend) return false;

    // アニメーション中なら終了
    if(this.animation) return false;

    this.animation = true;

    this.$toggle.removeClass('is_stateActive');

    ENGINE.store.$body.removeClass('NAVIGATION_OPEN');

    this.$target.velocity('fadeOut', { duration: 300, easing: 'ease', complete: () => {
      ENGINE.store.navigationOpend = false;
      this.animation = false;

      // callback関数の実行 (使用箇所 : topページ)
      if (this.$toggle.data('callback-func') && (typeof(this.$toggle.data('callback-func')) == 'function')) {
        this.$toggle.data('callback-func')(false);
      }
    }});
  }
};


/**
* クリニック一覧
*/
ENGINE.clinic = {
  $btn      : $('.js_clinic__btn'),
  $close    : $('.js_clinic__close'),
  $target   : $('#l_clinic'),
  animation : false,

  // 初期化
  init: function() {
    this.$btn.on('click', e => {
      this.toggle();
    });

    this.$close.on('click', e => {
      this.close();
    });
  },

  // 開閉の切り替え
  toggle: function() {
    if(ENGINE.store.clinicOpend) {
      this.close();
    } else {
      this.open();
    }
  },

  // 開く
  open: function() {

    // メニューが開いていれば終了
    if(ENGINE.store.clinicOpend) return false;

    if(ENGINE.store.navigationOpend) {
      ENGINE.navigation.close(false);
    }

    this.$btn.addClass('is_stateActive');

    this.$target.css({ zIndex: 50001 }).addClass('is_stateActive');
    ENGINE.store.clinicOpend = true;
  },

  // 閉じる
  close: function() {
    // メニューが開いていれば終了
    if(!ENGINE.store.clinicOpend) return false;

    this.$btn.removeClass('is_stateActive');
    this.$target.css({ zIndex: '' }).removeClass('is_stateActive');
    ENGINE.store.clinicOpend = false;
  }
};


/**
* bodyのスクロールを固定する
*/
ENGINE.noneScroll = {
  position: false,
  $window: $(window),
  $body: $('body'),
  set: function() {
    this.position = this.$window.scrollTop();
    this.$body
    .addClass('BODY_FIXED')
    .css({
      position: 'fixed',
      width: '100%',
      height: '100%',
      overflowX: 'hidden',
      overflowY: 'scroll',
      top: Math.abs(this.position) * -1
    });
  },
  unset: function() {
    if(this.position === false) return false;
    this.$body
    .removeClass('BODY_FIXED')
    .css({
      position: '',
      width: '',
      height: '',
      overflowX: '',
      overflowY: '',
      top: ''
    });

    this.$window.scrollTop(Math.abs(this.position));
    this.position = false;
  },
};



/**
* シンプルなアコーディオン
*/
ENGINE.simpleAchordion = {
  elements: $('.js_simpleAchordion'),
  init: function() {
    this.elements.on('click', e => {
      const $btn = $(e.currentTarget);

      if($btn.hasClass('is_stateAnimation')) return false;

      if($btn.hasClass('is_stateActive')) {
        this.close($btn);
      } else {
        this.open($btn);
      }
      return true;
    })
  },

  open: function($btn) {
    $btn.addClass('is_stateAnimation is_stateActive').next().velocity('slideDown', { duration: 300, easing: 'ease', complete: () => { $btn.removeClass('is_stateAnimation') }});
  },

  close: function($btn) {
    $btn.addClass('is_stateAnimation').removeClass('is_stateActive').next().velocity('slideUp', { duration: 300, easing: 'ease', complete: () => { $btn.removeClass('is_stateAnimation') }});
  }
}



/**
* スムーススクロール
*/
ENGINE.smoothScroll = {
  animation: false,

  init: function() {
    const $body = $('body');
    $('.l_pageTop').on('click', ()=>{
      this.execute($($body));
    });

    // .js_anchor__excludeをつけると対象外に出来る。
    $('.l_main a[href^="#"]:not(".js_anchor__exclude")').on('click', e => {
      const href = $(e.currentTarget).attr('href');
      if(href === '#' || href === '') return false;
      this.execute($(href));
      return false;
    });
  },

  execute: function($target, Offset) {
    if(!$target) {
      return false;
    }

    if(this.animation) {
      return false;
    }
    this.animation= true;

    const off = Offset ? Offset: 0;

    $target.velocity("scroll", {
      duration: 1000,
      easing: "easeOutExpo",
      offset: off,
      complete: () => {
        this.animation = false;
      }
    });
    return false;
  },
}





/*
*//* オブジェクトの監視
*/
ENGINE.effect = {
  init: function() {
    const rate = window.innerHeight / window.innerWidth;
    let options;

    if(rate > 0) {
      options = { rootMargin: '-40% 0px -40% 0px'};
    } else {
      options = { rootMargin: '-20% 0px -20% 0px'};
    }

    const entryCallback = (entries, observer) => {
      entries.forEach(entry => {
        const el = entry.target;
        if(entry.isIntersecting) {

          // 初回発動しないようにする
          if(el.classList.contains('ready')) {
            el.classList.add('entry');
          }
        }
      });
    }

    const exitCallback = (entries, observer) => {
      entries.forEach(entry => {
        const el = entry.target;

        // 画面外の場合
        if(!entry.isIntersecting) {
          el.classList.remove('entry');
          el.classList.add('ready');
        }
      });
    }

    this.entryObserver = new IntersectionObserver(entryCallback, options);
    this.exitObserver = new IntersectionObserver(exitCallback, {});
    const node = document.querySelectorAll('.js_effect');
    this.elements = Array.prototype.slice.call(node);
    this.start();
  },

  start: function() {
    this.elements.forEach(el => {
      this.exitObserver.observe(el);
      this.entryObserver.observe(el);
    });
  },

  stop: function() {
    this.elements.forEach(el => {
      this.entryObserver.unobserve(el);
      this.exitObserver.unobserve(el);
    });
  }
}


ENGINE.footerAchordion = {
  el: $('.l_sitemap__titlePlus'),
  init: function() {

    this.el.on('click', e => {
      const $this = $(e.currentTarget);
      if(window.innerWidth <= 768 && !$this.hasClass('is_stateAnimation')) {
        if($this.hasClass('is_stateActive')) {
          $this.addClass('is_stateAnimation').removeClass('is_stateActive').next().velocity('slideUp', { duration: 300, complete: () => {
            $this.removeClass('is_stateAnimation');

            // callback関数の実行 (使用箇所 : topページ)
            if ($this.data('callback-func') && (typeof($this.data('callback-func')) == 'function')) {
              $this.data('callback-func')();
            }
          }});
        } else {
          $this.addClass('is_stateAnimation is_stateActive').next().velocity('slideDown', { duration: 300, complete: () => {
            $this.removeClass('is_stateAnimation');

            // callback関数の実行 (使用箇所 : topページ)
            if ($this.data('callback-func') && (typeof($this.data('callback-func')) == 'function')) {
              $this.data('callback-func')();
            }
          }});
        }
      }
    });

    $(window).on('MATCH_MEDIA_768_OVER', () => {
      this.reset();
    })
  },

  reset: function() {
    this.el.removeClass('is_stateActive is_stateAnimation').next().css({display: ''});
  }
}


ENGINE.matchMedia = {
  init: function() {
    window.matchMedia('screen and (max-width:768px)').addListener( function(e) {
      $(window).trigger('MATCH_MEDIA_EXCHANGE');
      $(window).trigger('MATCH_MEDIA_768');
      if(!e.matches) {
        $(window).trigger('MATCH_MEDIA_768_OVER');
      } else {
        $(window).trigger('MATCH_MEDIA_768_UNDER');
      }
    });
  }
}


ENGINE.viewContent = {
  init: function() {
    $(window).on('scroll', () => {
      const pos = $(window).scrollTop();
      if(pos > 100) {
        $('body').addClass('VIEW_CONTENTS');
      } else {
        $('body').removeClass('VIEW_CONTENTS');
      }
    })
  }
}


ENGINE.lazyload = {
  init: function() {
    var lazyLoadInstance = new LazyLoad({
      elements_selector: ".js_lazy",
    });
  }
}


/**
* 発火用
*/
window.addEventListener('DOMContentLoaded', function() {

  ENGINE.viewContent.init();

  ENGINE.navigation.init();
  ENGINE.clinic.init();

  ENGINE.simpleAchordion.init();

  ENGINE.smoothScroll.init();

  ENGINE.footerAchordion.init();

  ENGINE.matchMedia.init();

  ENGINE.lazyload.init();

  ENGINE.effect.init();
});
